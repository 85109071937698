<template>
  <div>
    <div v-if="!loading && dataReady"
         class="animated fadeIn">
      <b-card body-class="p-0">
        <b-card-header>
          <b-row>
            <b-col cols="8">
              <h4 class="mt-2">Histórico de Locações</h4>
            </b-col>
            <b-col v-if="!activeBooking && driver.status !== 'BANNED'"
                   cols="4"
                   class="text-right mt-1">
              <b-button variant="success"
                        @click="goTo(null)"
              >
                Nova locação
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body body-class="p-0">
          <b-table responsive
                   striped
                   hover
                   :items="bookings.items"
                   :fields="fields"
                   @row-clicked="goTo">
            <template v-slot:cell(plan)="data">
              <strong>{{ data.item.plan.name }}</strong>
            </template>
            <template v-slot:cell(amount)="data">
              {{ setPriceFormat(data.item.transaction_amount) }}
              <span v-if="data.item.transaction_discount > 0"
                    class="small text-muted">(Desc. {{ setDiscountFormat(data.item.transaction_discount) }})</span>
            </template>
            <template v-slot:cell(kind_open)="data">
              <span class="text-nowrap">
                {{ getOpenKindById(data.item.kind_opened_id) }}
              </span>
            </template>
            <template v-slot:cell(car)="data">
              <span v-if="data.item.car && data.item.car !== null">
                <b-link :title="getCarInfos(data.item.car)"
                        variant="link"
                        @click.stop="goToCar(data.item.car.id)">
                  {{ data.item.car.license_plate || ' --- ' }}
                </b-link>
              </span>
              <span v-else>
                <span v-if="data.item.status === 'CLOSED' || data.item.status === 'CANCELED'">-</span>
                <span v-else>Aguardando...</span>
              </span>
            </template>

            <template v-slot:cell(started_at)="data">
              {{ data.item.started_at | moment('DD/MM/YYYY') }}
            </template>
            <template v-if="$regions.isBrazil() && groups.includes('SQUAD-FLEET')"
                      v-slot:cell(data_inicio)="data">
              <span v-if="findStatusBooking(data.item.id).data_inicio" class="text-dark">
                {{ findStatusBooking(data.item.id).data_inicio | moment('DD/MM/YYYY') }}
              </span>
              <span v-else class="text-dark">-</span>
            </template>
            <template v-slot:cell(finished_at)="data">
              <span v-if="data.item.finished_at">{{ data.item.finished_at | moment('DD/MM/YYYY') }}</span>
              <span v-else>-</span>
            </template>
            <template v-if="$regions.isBrazil() && groups.includes('SQUAD-FLEET')"
                      v-slot:cell(data_fim)="data">
              <span v-if="findStatusBooking(data.item.id).data_fim"
                    class="text-dark">
                {{ findStatusBooking(data.item.id).data_fim | moment('DD/MM/YYYY') }}
              </span>
              <span v-else class="text-dark">-</span>
            </template>
            <template v-if="$regions.isBrazil() && groups.includes('SQUAD-FLEET')"
                      v-slot:cell(data_validacao)="data">
              <span v-if="findStatusBooking(data.item.id).data_validacao" class="text-dark">
                {{ findStatusBooking(data.item.id).data_validacao | moment('DD/MM/YYYY') }}
              </span>
              <span v-else class="text-dark">-</span>
            </template>
            <template v-if="$regions.isBrazil() && groups.includes('SQUAD-FLEET')"
                      v-slot:cell(data_criacao)="data">
              <span v-if="findStatusBooking(data.item.id).data_criacao"
                    class="text-dark">
                {{ findStatusBooking(data.item.id).data_criacao | moment('DD/MM/YYYY') }}
              </span>
              <span v-else class="text-dark">-</span>
            </template>
            <template v-slot:cell(duration)="data"> {{ getDuration(data.item) }} dias</template>
            <template v-if="$regions.isBrazil()"
                      v-slot:cell(indicacao_infracao)="data">
              <span v-if="!findStatusBooking(data.item.id).status">
                <b-spinner class="align-middle" small/>
              </span>
              <span v-else
                    v-b-tooltip.hover.left="findStatusBooking(data.item.id).description">
                <b-badge :variant="findStatusBooking(data.item.id).variant">
                  {{ findStatusBooking(data.item.id).status }}
                </b-badge>
              </span>
            </template>
            <template v-slot:cell(status)="data">
              <span v-b-tooltip.html
                    :title="mountOperationInfoBooking(data.item)">
                <b-badge>
                  <i class="fa fa-info-circle ml-1"/>
                  {{ data.item.status }}
                </b-badge>
              </span>
            </template>
            <template v-slot:cell(kind_close)="data">
              <span class="text-nowrap">
                {{ getCloseKindById(data.item.kind_closed_id) }}
              </span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="bookings.errorType || bookings.items.length === 0" class="p-0">
          <content-controll :service="bookings"/>
        </b-card-body>
      </b-card>
      <modal-booking :modal_data="modal_data"
                     :allBookings="bookings.items"/>
      <modal-docs-upload/>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </div>
</template>

<script>
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import { formatPrice, formatNumber } from '@utils/numbers';
import ModalBooking from '@components/modals/booking';
import { makeQuery } from '@graphql/middleware';
import { mapActions } from 'vuex';
import DRIVER_BOOKINGS from '@graphql/booking/queries/booking-by-driver.gql';
import GET_OPENING_KINDS from '@graphql/booking/queries/get-booking-opening-kinds.gql';
import GET_CLOSING_KINDS from '@graphql/booking/queries/get-booking-closing-kinds.gql';
import GET_DRIVER from '@graphql/driver/queries/get.gql';
import ModalDocsUpload from '@components/modals/DocsUpload/DocsUpload';
import { mapGetters } from 'vuex';

export default {
  name: 'DriverBookings',
  page: {
    title: 'Motoristas',
  },
  components: {
    ContentControll,
    ContentLoading,
    ModalBooking,
    ModalDocsUpload,
  },
  data: () => {
    return {
      today: new Date(),
      fields: [
        {
          key: 'plan',
          label: 'Plano',
        },
        {
          key: 'amount',
          label: 'Valor',
        },
        {
          key: 'kind_open',
          label: 'Motivo de Abertura',
        },
        {
          key: 'car',
          label: 'Carro',
        },
        {
          key: 'started_at',
          label: 'Data início',
          sortable: true,
        },
        {
          key: 'finished_at',
          label: 'Data fim',
          sortable: true,
        },
        {
          key: 'duration',
          label: 'Duração',
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
          class: 'text-right',
        },
        {
          key: 'kind_close',
          label: 'Motivo de Fechamento',
        },
      ],
      modal_data: {
        id: null,
        driver: null,
        recurrency: null,
        transaction_method: null,
        transaction_discount: '',
        pre_transaction_method: null,
        pre_transaction_installments: 0,
        pre_transaction_discount: 0,
        plan: null,
        car: null,
        started_at: null,
        finished_at: null,
      },
      openKinds: null,
      closeKinds: null,
      bookings: {},
      statusBooking: [],
      dataReady: false,
      loading: true,
      activeBooking: null,
    };
  },
  computed: {
    ...mapGetters('user', ['groups']),
  },
  mounted() {
    makeQuery(DRIVER_BOOKINGS, {
      id: this.$route.params.id,
    }).then(result => {
      this.bookings = result.data.bookingsByDriver;
      const status = [];
      for (let i = 0; i < this.bookings.items.length; i++) {
        status.push(this.bookings.items[i].status);
      }
      this.activeBooking = status.includes('ACTIVE') || status.includes('PENDING');

      this.loading = false;
      this.dataReady = true;

      const { contract } = this.$router.history.current.params;
      setTimeout(() => {
        if (contract) {
          const openModal = this.bookings.items.find(el => el.contract.includes(contract));
          this.goTo(openModal);
        }
      }, 1000);

      this.getAllStatusBooking();
    });
    this.driverDocumentsAction({
      driverId: this.$route.params.id,
      conditionals: {
        status: ['APPROVED', 'SENT'],
      },
    });
  },
  apollo: {
    driver: {
      query: GET_DRIVER,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    open_kinds: {
      query: GET_OPENING_KINDS,
      update: data => data,
      result(data) {
        if (!data.loading) {
          const openingKinds = data.data.getBookingOpeningKinds.items;
          // TODO: open kind not available in mx and not transalated on backend
          const region = 'pt-br';

          this.openKinds = openingKinds.map(kind => {
            return {
              labelTranslated: kind.translate[region],
              type: kind.type,
              id: kind.id,
            };
          });
        }
      },
    },
    close_kinds: {
      query: GET_CLOSING_KINDS,
      update: data => data,
      result(data) {
        if (!data.loading) {
          const closingKinds = data.data.getBookingClosingKinds.items;
          const region = (this.$regions.getCurrentRegion().lcid ?? '').toLowerCase();

          this.closeKinds = closingKinds.map(kind => {
            return {
              labelTranslated: kind.translate[region],
              type: kind.type,
              id: kind.id ?? 'none',
            };
          });
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setBookingSelect: 'driver/setBookingSelect',
      driverDocumentsAction: 'driverDocument/getByDriverId',
    }),
    getDuration(item) {
      if (!item) return '';
      let start = this.$moment.utc(item.started_at);
      let end_count = item.finished_at ? this.$moment.utc(item.finished_at) : this.$moment.utc(new Date());
      return end_count.diff(start, 'days');
    },
    setPriceFormat(_value) {
      return formatPrice('R$', _value);
    },
    setDiscountFormat(_value) {
      return formatNumber(_value) + '%';
    },
    getCarInfos(car) {
      if (car.brand?.name && car.model?.name) {
        return car.brand.name + ' / ' + car.model.name;
      }
      return 'Sem informações do carro';
    },
    goTo(_item) {
      this.setBookingSelect(_item);
      if (_item === null) {
        this.modal_data.id = null;
        this.modal_data.driver = this.$route.params.id;
        this.modal_data.status = null;
        this.modal_data.recurrency = null;
      } else {
        this.modal_data.id = _item.id;
        this.modal_data.plan = _item.plan.id;
        this.modal_data.car = _item.car ? _item.car.id : null;
        this.modal_data.driver = this.$route.params.id;
        this.modal_data.recurrency = _item.recurrency;
        this.modal_data.transaction_method = _item.transaction_method ? _item.transaction_method.id : null;
        this.modal_data.transaction_discount = _item.transaction_discount;
        this.modal_data.pre_transaction_method = _item.pre_transaction_method ? _item.pre_transaction_method.id : null;
        this.modal_data.pre_transaction_installments = _item.pre_transaction_installments;
        this.modal_data.pre_transaction_discount = _item.pre_transaction_discount;
        this.modal_data.started_at = _item.started_at;
        this.modal_data.finished_at = _item.finished_at;
        this.modal_data.status = _item.status;
        this.modal_data.active_car = _item.car ? `${_item.car.license_plate}( ${_item.car.brand.name} / ${_item.car.model.name} )` : null;
        this.modal_data.kind_closed_id = _item.kind_closed_id;
        this.modal_data.kind_opened_id = _item.kind_opened_id;
      }
      this.$root.$emit('bv::show::modal', 'modalBooking');
    },
    getAllStatusBooking() {
      if (!this.$regions.isBrazil()) return;

      const isFleetSquad = this.groups.includes('SQUAD-FLEET');

      if (isFleetSquad) {
        this.fields.splice(5, 0, {
          key: 'data_inicio',
          label: 'Inicio da Indicação',
          class: 'text-center text-primary',
        });

        this.fields.splice(7, 0, {
          key: 'data_fim',
          label: 'Validade para Indicação',
          class: 'text-center text-primary',
        });

        this.fields.splice(8, 0, {
          key: 'data_validacao',
          label: 'Última Análise de Documentos',
          class: 'text-center text-primary',
        });

        this.fields.splice(9, 0, {
          key: 'data_criacao',
          label: 'Primeiro Envio de Documentos',
          class: 'text-center text-primary',
        });

        this.fields.splice(10, 0, {
          key: 'indicacao_infracao',
          label: 'Indicação de Infração',
          class: 'text-center',
        });
      } else {
        this.fields.splice(7, 0, {
          key: 'indicacao_infracao',
          label: 'Indicação de Infração',
          class: 'text-center',
        });
      }

      Promise.all(
        this.bookings.items.map(async booking => {
          const contract = await this.getContractBooking(booking.id);
          const variants = {
            reprovado: 'danger',
          };
          const title = {
            aprovado: 'Aprovado',
            reprovado: 'Documentação Rejeitada',
            pendente: 'Documentação em Análise',
          };
          const description = {
            aprovado: 'Nosso provedor de multas certificou que o motorista está apto a receber indicações de infrações futuras.',
            reprovado: `Nosso provedor de infrações de trânsito invalidou esse contrato, logo não conseguimos fazer indicação de nenhuma infração ao condutor.
            Isso pode causar geração de NICs. Devemos pedir atualização de anexos ao Booking.`,
            pendente: `Estamos aguardando nosso provedor de infrações de trânsito analisar os documentos anexados junto ao booking para validação da assinatura,
            data de validade e condições da CNH.`,
          };
          const variant = variants[contract?.status];

          return {
            ...contract,
            id: booking.id,
            status: title[contract?.status?.toLowerCase()] || 'N/A',
            description: description[contract?.status?.toLowerCase()],
            variant,
          };
        })
      ).then(res => (this.statusBooking = res));
    },
    getOpenKindById(id) {
      if (!id) return '-';
      const kind = this.openKinds.find(kind => kind.id === id);

      if (!kind) return this.fallbackReasonLabel('open');
      return kind.labelTranslated;
    },
    getCloseKindById(id) {
      if (!id) return '-';
      const kind = this.closeKinds.find(kind => kind.id === id);

      if (!kind) return this.fallbackReasonLabel('close');
      return kind.labelTranslated;
    },
    fallbackReasonLabel(type) {
      return type === 'open' ? this.$i18n.t('modalBooking.words.openKindNotFound') : this.$i18n.t('modalBooking.words.reasonForClosingNotFound');
    },
    findStatusBooking(bookingId) {
      return this.statusBooking.find(el => el.id === bookingId) || 'N/A';
    },
    getContractBooking(bookingId) {
      return this.$fleetProvider.get(`/fleet-tickets/tickets/provider/contract/${bookingId}`).then(res => res.data);
    },
    goToCar(_id) {
      this.$router.push({
        path: `/fleet/${_id}`,
      });
    },

    mountOperationInfoBooking(item) {
      let text = '';
      if (item.opened_by) text += '<p><b> ' + this.$i18n.t('modalBooking.texts.openedBy') + '</b> ' + item.opened_by + '</p>';
      if (item.activated_by) text += '<p><b> ' + this.$i18n.t('modalBooking.texts.activatedBy') + ' </b>' + item.activated_by + '</p>';
      if (item.closed_by) text += '<p><b> ' + this.$i18n.t('modalBooking.texts.closedBy') + ' </b>' + item.closed_by + '</p>';
      return text;
    },
  },
};
</script>
